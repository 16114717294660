/*!

=========================================================
* BootstrapVue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard-pro
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import lang from 'element-ui/lib/locale/lang/ja';
import locale from 'element-ui/lib/locale';
import { store } from './store/store';
import Vue2TouchEvents from 'vue2-touch-events';
import VueClipboard from 'vue-clipboard2';
// router setup
import router from './routes/router';

import socketClient from './services/socket/SOCKET';

if (process.env.NODE_ENV === 'production') {
	console.log = function () { };
}

// Setup socket connection
socketClient.setup();
socketClient.listen('disconnect', function (reason) {
	console.log({ socketEvent: 'Disconnected! ', reason });
});

locale.use(lang);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(locale);
Vue.use(Vue2TouchEvents, {
	touchHoldTolerant: 0,
	swipeTolerance: 100,
});
Vue.use(VueClipboard);
/* eslint-disable no-new */
new Vue({
	el: '#app',
	render: (h) => h(App),
	store,
	router,
});
