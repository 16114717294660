import ApiService from './api.service';

const serviceURL = 'client';
const ClientService = {
	/**
	 * Acquire Client by client ID
	 * @param {Integer} clientId
	 * @returns {Object} project details
	 */
	get(id) {
		return ApiService.get(`${serviceURL}/`, `${id}`);
	},
	getClientById(id) {
		return ApiService.get(`${serviceURL}/${id}`);
	},
	getAllClient() {
		return ApiService.get(`${serviceURL}/`);
	},

	/**
	 * Update client content from text box
	 * @param {Integer} clientId: Client ID
	 * @param {Object} contents: Client contents for updating
	 * @returns
	 */
	update(clientId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${clientId}`, body);
	},

	/**
	 * Create new delivery record
	 * @param {Object} body {doc_content: {delivery Object}}
	 * @returns {Object} include success message and record ID
	 */
	register(contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Get client by query string
	 * @param {Object} param query object
	 * @returns {Object} project object
	 */
	search(param) {
		// return ApiService.query(`${serviceURL}/search?${param.userType}=${param.userId}`);
		return ApiService.query(`${serviceURL}/search`, param);
	},
	/**
	 * @param {Integer} clientId
	 * @returns {Promise} [response,error]
	 * **/
	createLineVerifyUrl(clientId) {
		return ApiService.post(`${serviceURL}/line_token/`, {
			doc_content: {
				client_id: clientId,
			},
		});
	},
	/**
	 * @param {Integer} clientId
	 * @returns {Promise} [response,error]
	 * **/
	getLineVerifyUrl(clientId) {
		return ApiService.get(`${serviceURL}/line_token/${clientId}`);
	},

};

export default ClientService;
