import ApiService from './api.service';

const serviceURL = 'user';
const UserService = {
	/**
	 * get all user info
	 * @returns {Promise} [response,error]
	 */
	getAll() {
		return ApiService.get(`${serviceURL}/`);
	},

	/**
	 * Acquire User by user ID
	 * @param {Integer} userId
	 * @returns {Promise} [response,error]
	 */
	get(userId) {
		return ApiService.get(`${serviceURL}/${userId}`);
	},

	/**
	 * Update User by user ID
	 * @param {Integer} userId
	 * @param {Object} contents
	 * @returns {Promise} [response,error]
	 */
	update(userId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${userId}`, body);
	},
	/**
	 * Create User
	 * @param {Object} contents
	 * @returns {Promise} [response,error]
	 */
	CreateUser(contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.post(`${serviceURL}/`, body);
	},
	/**
	 * @param {Object} params
	 * @returns {Promise} [response,error]
	 */
	checkExists(params) {
		return ApiService.post(`${serviceURL}/find`, {
			doc_content: params,
		});
	},
	/**
	 * Login user
	 * @param {String} username
	 * @param {String} password
	 * @returns {Promise} [response,error]
	 */
	login(username, password) {
		return ApiService.post(`${serviceURL}/login`, {
			doc_content: {
				username: username,
				login_password: password,
			},
		});
	},
	/**
	 * register user
	 * @param {Object} body user information
	 * @returns {Promise} [response,error]
	 */
	register(body) {
		return ApiService.post(`${serviceURL}/register`, {
			doc_content: {
				...body,
			},
		});
	},
	/**
	 * get user information
	 * @cookie access_token
	 * @returns {Promise} response,error]
	 */
	getInfo() {
		return ApiService.get(`${serviceURL}/info`);
	},
	/**
	 * reset password user
	 * @param {String} email_address user email
	 * @returns {Promise} response,error]
	 */
	resetPassword(email_address, loginId) {
		return ApiService.post(`${serviceURL}/resetpassword`, {
			doc_content: {
				email_address: email_address,
				username: loginId,
			},
		});
	},
	/**
	 * logout user
	 * @cookie access_token
	 * @returns {Promise} [response,error]
	 */
	logout() {
		return ApiService.get(`${serviceURL}/logout`);
	},
	/**
	 * @param {String} email_address
	 * @returns {Promise} [response,error]
	 * **/
	resendVerifyEmail(email_address) {
		return ApiService.post(`${serviceURL}/resendverifyemail`, {
			doc_content: {
				email_address: email_address,
			},
		});
	},
	/**
	 * @param {Integer} userId
	 * @returns {Promise} [response,error]
	 * **/
	createLineVerifyUrl(userId) {
		return ApiService.post(`${serviceURL}/line_token/`, {
			doc_content: {
				user_id: userId,
			},
		});
	},
	/**
	 * @param {Integer} userId
	 * @returns {Promise} [response,error]
	 * **/
	getLineVerifyUrl(userId) {
		return ApiService.get(`${serviceURL}/line_token/${userId}`);
	},

	verifyPassword(userId, password) {
		return ApiService.post(`${serviceURL}/check_password`, {
			doc_content: {
				user_id: userId,
				password: password,
			},
		});
	}
};

export default UserService;
