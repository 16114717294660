<template>
	<div class="l-wrapper">
		<div class="l-stage">
			<div class="l-stageInner">
				<div class="notFoundPage">
					<div class="notFoundPage__wrapper">
						<div class="notFoundPage__container">
							<div class="notFoundPage__header">
								<img
									class="notFoundPage__logo"
									:src="require('@/assets/Logo/ComodeIcon.svg')"
								/>
							</div>
							<div class="notFoundPage__body">
								<div class="notFoundPage__contentBlock">
									<img
										class="notFoundPage__errorLogo"
										:src="require('@/assets/Logo/NotFoundIcon.svg')"
									/>
									<div class="notFoundPage__title">
										<div class="notFoundPage__caption">404 Not Found</div>
										<div class="notFoundPage__caption">
											お探しのページは見つかりませんでした
										</div>
									</div>
									<div v-if="!hideButton" class="notFoundPage__directButton">
										<button :disabled="disabledButton" @click="onDirect">
											{{ buttonName }}
										</button>
									</div>
								</div>
							</div>
							<div class="notFoundPage__footer">
								<img
									class="notFoundPage__logo"
									:src="require('@/assets/Logo/ComodeIcon.svg')"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProjectService from '@/services/project.service';
import UserService from '@/services/user.service';
import ClientService from '@/services/client.service';

import { mapState } from 'vuex';

import CommonConst from '../../util/const';

export default {
	name: 'NotFoundPage',
	data() {
		return {
			buttonName: 'TOPページへ',
			disabledButton: false,
			projectInfo: {},
			queryParams: {},
			userId: undefined,
			authority: undefined,
			redirectToLogin: false,
			hideButton: false,
		};
	},
	computed: {
		...mapState(['userInfo', 'isAuthenticated']),
	},
	methods: {
		onDirect() {
			let url = '';
			if (this.redirectToLogin) {
				url = '/adminApp/login';
			} else if (this.isAuthenticated) {
				let menuPath =
					this.userInfo.authority == 2
						? 'admin'
						: this.userInfo.authority == 1
						? 'creator'
						: 'client';
				url = `/adminApp/${menuPath}menu/${this.userInfo.id}${
					this.userInfo.authority != 3 ? '/' + this.userInfo.authority : ''
				}/projectmenu`;
			} else {
				let menuPath =
					this.authority == 2 ? 'admin' : this.authority == 1 ? 'creator' : 'client';
				url = `/adminApp/${menuPath}menu/${this.userId}${
					this.authority != 3 ? '/' + this.authority : ''
				}/projectmenu`;
			}
			window.location.href = url;
		},
		async _getProject(projectId) {
			let [response, error] = await await ProjectService.get(projectId);
			if (error) {
				console.log(error);
				return {};
			}
			return response;
		},
		async _getUser(usertId) {
			let [response, error] = await await UserService.get(usertId);
			if (error) {
				console.log(error);
				return {};
			}
			return response;
		},
		async _getClient(clientId) {
			let [response, error] = await await ClientService.getClientById(clientId);
			if (error) {
				console.log(error);
				return {};
			}
			return response;
		},
		async clientWebhandle() {
			let projectInfo = await this._getProject(this.queryParams.projectId);
			if (Object.keys(projectInfo).length != 0) {
				let type = this.queryParams.type;
				if (type == 'client') {
					let clientInfo = await this._getClient(projectInfo.client_id);
					if (Object.keys(clientInfo).length == 0 || clientInfo.delete_flag == 1) {
						this.buttonName = CommonConst.NOT_FOUND_BTN_NAME.userDelete;
						this.disabledButton = true;
						return;
					}
				}
				let userInfo = await this._getUser(
					type == 'creator' ? projectInfo.responsible_user : projectInfo.client_id
				);
				if (Object.keys(userInfo).length != 0 && userInfo.delete_flag == 0) {
					// Redirect to project Menu page
					if (this.isAuthenticated) {
						this.buttonName = CommonConst.NOT_FOUND_BTN_NAME.projectMenu;
					} else {
						this.buttonName = CommonConst.NOT_FOUND_BTN_NAME.login;
						this.redirectToLogin = true;
					}
					this.userId = userInfo.id;
					this.authority = userInfo.authority;
				} else {
					if(type == 'creator') {
						this.buttonName = CommonConst.NOT_FOUND_BTN_NAME.userDelete;
						this.disabledButton = true;
					}
					else {
						this.hideButton = true;
					}
				}
			} else {
				if (!this.isAuthenticated) {
					this.buttonName = CommonConst.NOT_FOUND_BTN_NAME.login;
					this.redirectToLogin = true;
				} else {
					this.buttonName = CommonConst.NOT_FOUND_BTN_NAME.projectMenu;
				}
			}
		},
	},
	mounted() {
		this.queryParams = this.$route.query;
		if (!this.queryParams.type) {
			this.buttonName = CommonConst.NOT_FOUND_BTN_NAME.login;
			this.redirectToLogin = true;
		} else if (this.queryParams.type != 'admin') {
			this.clientWebhandle();
		}
	},
};
</script>
