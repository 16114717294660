const DELETE_MODE = 'onDelete';
const EDIT_MODE = 'onEdit';
const EDIT_HOLDINGTAX_MODE = 'onEditHoldingTax';
const EXPIRATION = 31536000;
const POST_CODE_LENGTH = 8;
const FREEZE_STATUS = 10;
const ALERT_TIMEOUT = 5000;
const POSTCODE_LENGHT = 7;
const PWD_REGEX =
	'・アルファベットの大文字と小文字と数字\n・ピリオド(.)、スラッシュ(/)、 クエスチョンマーク(?)、 ハイフン(-)\n・6文字以上20文字以下';

const PRE_TEXT_LINE_LINK =
	'CoMoDe LINE公式アカウントのご登録ありがとうございます。\n入力欄に表示されている英数字のコードを、そのまま送信してください。\nコード送信後に、登録完了となります。\n';

const STATUS_FOR_SEARCH = {
	キックオフミーティング: 'hearing',
	スケジュール: 'schedule',
	// 見積書作成: 'estimate',
	コンセプトデザイン: 'decision',
	// クリエイティブ: 'creative',
	// ご提案: 'proposal',
	// 再提案設定: 'addcost',
	// 再提案: 'reproposal',
	コンセプト仕上げ: 'delivery',
	デジタル認証: 'nft',
	完了: 'finish',
	スタート: '',
};

const DAY_NAMES = ['月', '火', '水', '木', '金', '土', '日'];
const MONTH_NAMES = [
	'1月',
	'2月',
	'3月',
	'4月',
	'5月',
	'6月',
	'7月',
	'8月',
	'9月',
	'10月',
	'11月',
	'12月',
];

const FINISH_STATUS = 5;
const NOT_FOUND_BTN_NAME = {
	login: 'ログイン画面へ',
	projectMenu: '進行中の案件画面へ',
	userDelete: 'ユーザが削除されました',
};

const IGNORE_FIELD = [
	'contact_email_2',
	'contact_email_3',
	'account_holder',
	'account_type',
	'address',
	'bank_name',
	'branch_name',
	'company_name',
	'consumption_tax_rate',
	'fax_number',
	'phone_number',
	'postal_code',
	'remark',
	'represent_name',
	'username',
	'company_seal',
];

export default {
	DELETE_MODE,
	EDIT_MODE,
	EXPIRATION,
	POST_CODE_LENGTH,
	FREEZE_STATUS,
	ALERT_TIMEOUT,
	POSTCODE_LENGHT,
	PWD_REGEX,
	STATUS_FOR_SEARCH,
	DAY_NAMES,
	MONTH_NAMES,
	FINISH_STATUS,
	NOT_FOUND_BTN_NAME,
	EDIT_HOLDINGTAX_MODE,
	PRE_TEXT_LINE_LINK,
	IGNORE_FIELD,
};
