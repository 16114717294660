import axiosBase from 'axios';
import CONFIG from './config'
const apiUrl = '/api/v1';

const axios = axiosBase.create({
	// This is development host
	baseURL: 'https://api.nftgarden.app',
});

axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
	'Content-Type': 'application/json',
	'x-api-key': CONFIG.API_KEY,
};

const catchError = (error) => {
	console.log(`ApiService: ${error}`);
	if (error.response) {
		// Request made and server responded
		return [undefined, error.response.data];
	} else if (error.request) {
		// The request was made but no response was received
		console.log(error.request);
		return [undefined, error];
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', error);
		return [undefined, error];
	}
};

const responseCallback = (res) => {
	return [res.data, undefined];
};

const NFTService = {
	createNFT(body) {
		return axios
			.post(`${apiUrl}/createnft`, body)
			.then(responseCallback)
			.catch(catchError);
	},
	/**
	 * get NFT list 
	 * @param {String} collection_id 
	 * @returns 
	 */
	getNFTs(collection_id, blockchain) {
		let body = {
			contract_address: collection_id,
			blockchain: blockchain,
		};
		return axios
			.post(`${apiUrl}/getnftgdnids`, body)
			.then(responseCallback)
			.catch(catchError);
	},
	/**
	 * get NFT list 
	 * @param {String} collection_id 
	 * @returns 
	 */
	getNftDetails(nft_gdn_ids) {
		let body = {
			nft_gdn_ids: nft_gdn_ids
		};
		return axios
			.post(`${apiUrl}/getnfts`, body)
			.then(responseCallback)
			.catch(catchError);
	},
};

export default NFTService;
