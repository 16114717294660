<template>
	<validation-provider
		:rules="rules"
		:name="name"
		v-bind="$attrs"
		v-slot="{ errors, valid, invalid, validated }"
		class="w-100"
	>
		<b-form-group>
			<slot name="label">
				<div class="input-title-wrapper">
					<label v-if="label" :class="labelClasses">
						{{ label }}
					</label>
					<label class="required-text" v-if="inputRequired">[必須]</label>
					<label class="number-caption-text" v-if="numberCaption">[ハイフンなし]</label>
				</div>
			</slot>
			<div
				class="input-wrapper"
				:class="[
					{ 'input-group d-flex align-items-center': hasIcon },
					{ 'input-group-alternative': alternative },
					{ 'has-label': label || $slots.label },
					inputGroupClasses,
				]"
			>
				<slot v-bind="slotData">
					<input
						v-if="type != 'password'"
						:value="value"
						:type="type"
						v-on="listeners"
						v-bind="$attrs"
						:valid="valid"
						:required="required"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							{ 'display-inline-block': unit },
							inputClasses,
						]"
						:disabled="disabled"
						@focus="onFocusInput"
					/>
					<input
						:id="name"
						v-else
						:value="value"
						:type="type"
						v-on="listeners"
						v-bind="$attrs"
						:valid="valid"
						:required="required"
						@focus="onFocusPassword()"
						@blur="onBlurPassword()"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							{ 'display-inline-block': unit },
							inputClasses,
						]"
						:disabled="disabled"
					/>
					<div
						v-show="isShowListGroup"
						class="list-group"
						v-click-outside="clickOutSide"
					>
						<div
							v-for="(item, index) in datalist"
							:key="index"
							class="select-option"
							@click="onOptionClick(index, item)"
						>
							{{ item.name_kanji }}
						</div>
					</div>

					<div class="elm-title unit" v-if="unit">{{ unit }}</div>
				</slot>
				<div v-if="prependIcon" class="input-group-prepend">
					<span class="input-group-text">
						<slot>
							<div style="cursor: pointer" @click="iconClick" :class="prependIcon">
								{{ textContent }}
							</div>
						</slot>
					</span>
				</div>
			</div>
			<slot name="success">
				<div class="valid-feedback" v-if="valid && validated && successMessage">
					{{ successMessage }}
				</div>
			</slot>
			<slot name="note">
				<div v-if="note" style="display: block" class="note">
					{{ note }}
				</div>
			</slot>
			<slot name="note_error">
				<div v-if="noteError" style="display: block" class="note-error">
					{{ noteError }}
				</div>
			</slot>
			<slot name="error">
				<div class="invalid-feedback" style="display: block">
					{{ errors[0] }}
				</div>
			</slot>
		</b-form-group>
	</validation-provider>
</template>
<script>
export default {
	inheritAttrs: false,
	name: 'base-input',
	props: {
		noteError: {
			type: String,
			description: 'Note Error',
		},
		note: {
			type: String,
			description: 'Note',
		},
		required: {
			type: Boolean,
			description: 'Whether input is required (adds an asterix *)',
		},
		group: {
			type: Boolean,
			description: 'Whether input is an input group (manual override in special cases)',
		},
		alternative: {
			type: Boolean,
			description: 'Whether input is of alternative layout',
		},
		label: {
			type: String,
			description: 'Input label (text before input)',
		},
		error: {
			type: String,
			description: 'Input error (below input)',
		},
		successMessage: {
			type: String,
			description: 'Input success message',
			default: '',
		},
		labelClasses: {
			type: String,
			description: 'Input label css classes',
			default: 'form-control-label',
		},
		inputClasses: {
			type: String,
			description: 'Input css classes',
		},
		inputGroupClasses: {
			type: String,
			description: 'Input group css classes',
		},
		value: {
			type: [String, Number],
			description: 'Input value',
		},
		type: {
			type: String,
			description: 'Input type',
			default: 'text',
		},
		appendIcon: {
			type: String,
			description: 'Append icon (right)',
		},
		prependIcon: {
			type: String,
			description: 'Prepend icon (left)',
		},
		rules: {
			type: [String, Array, Object],
			description: 'Vee validate validation rules',
			default: '',
		},
		name: {
			type: String,
			description: 'Input name (used for validation)',
			default: '',
		},
		disabled: {
			type: Boolean,
			description: 'Disable input text',
		},
		inputRequired: {
			type: Boolean,
			description: 'Required input field',
		},
		numberCaption: {
			type: Boolean,
			description: 'Show number caption input field',
		},
		textContent: {
			type: String,
			description: 'Text content',
		},
		unit: {
			type: String,
			description: 'Unit after Input',
			default: '',
		},
		datalist: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			focused: false,
			isShowListGroup: true,
		};
	},
	directives: {
		clickOutside: {
			bind: function (el, binding, vnode) {
				el.clickOutsideEvent = function (event) {
					// here I check that click was outside the el and his children
					if (!(el == event.target || el.contains(event.target))) {
						// and if it did, call method provided in attribute value
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent);
			},
			unbind: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent);
			},
		},
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: this.updateValue,
				focus: this.onFocus,
				blur: this.onBlur,
			};
		},
		slotData() {
			return {
				focused: this.focused,
				error: this.error,
				...this.listeners,
			};
		},
		hasIcon() {
			const { append, prepend } = this.$slots;
			return (
				append !== undefined ||
				prepend !== undefined ||
				this.appendIcon !== undefined ||
				this.prependIcon !== undefined ||
				this.group
			);
		},
	},
	methods: {
		onFocusPassword() {
			document.getElementById(this.name).setAttribute('type', 'text');
		},
		onBlurPassword() {
			document.getElementById(this.name).setAttribute('type', 'password');
		},
		updateValue(evt) {
			let value = evt.target.value;
			this.$emit('input', value);
			if (!value && this.name === '銀行名') {
				this.$emit('on-bank-empty', value);
			}
			this.isShowListGroup = true;
		},
		updateBranch(evt) {
			let value = evt.target.value;
			if (!value) {
				this.$emit('input', value);
				this.$emit('on-branch-empty', value);
				setTimeout(() => {
					this.isShowListGroup = true;
				}, 200);
			}
		},
		onFocus(evt) {
			this.focused = true;
			this.$emit('focus', evt);
		},
		onBlur(evt) {
			this.focused = false;
			this.$emit('blur', evt);
		},
		iconClick() {
			this.$emit('on-icon-click');
		},
		onOptionClick(index, item) {
			console.log(index);
			this.$emit('on-select-option', item);
			this.isShowListGroup = false;
		},
		clickOutSide() {
			this.isShowListGroup = false;
		},
		onFocusInput(event) {
			if (this.name == '支店名') {
				this.updateBranch(event);
			} else {
				// console.log('Do nothing');
			}
		},
	},
};
</script>
<style lang="scss">
.note-error {
	font-size: 9.5px !important;
	white-space: pre-line;
	font-family: NotoSansJPRegular;
	height: 15px;
	color: red;
}

.custom-form-input-label {
	font-family: NotoSansJPBold;
	font-size: 14px !important;
	color: #808080;
	margin: 0;
}

.required-text {
	color: #38497c;
	font-size: 9px;
	margin-left: 8px;
	font-family: NotoSansJPBold;
	margin-bottom: 0;
}

.number-caption-text {
	color: #808080;
	font-size: 9px;
	margin-left: 8px;
	font-family: NotoSansJPBold;
	margin-bottom: 0;
}

.custom-input-class {
	background-color: #f7f8f8 !important;
	height: 32px !important;
	box-shadow: none !important;
	font-size: 12px !important;
	font-family: NotoSansJPRegular;
}
.display-inline-block {
	width: 90% !important;
	display: inline-block !important;
}
.unit {
	display: inline-block;
	margin-left: 3%;
}
.copy-btn {
	width: 140px;
	background-color: #808080;
	color: #ffff;
	border-radius: 34px;
	font-size: 14px;
	font-family: NotoSansJPRegular;
	display: flex;
	justify-content: center;
	padding: 5px 0 6px;
}

input:disabled {
	background-color: white !important;
}

.input-wrapper {
	position: relative;
}

.list-group {
	width: fit-content;
	max-height: 340px;
	overflow-y: auto;
	position: absolute;
	right: 0;
	border-radius: 5px;
	background-color: #fff;
	z-index: 2;
	font-family: NotoSansJPMedium;
	font-size: 12px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
	width: 100%;
	color: #808080;

	/* width */
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.select-option {
	width: 100%;
	list-style-type: none;
	background-color: #fff;
	padding: 5px 10px;
	margin: auto;
	text-align: left;
	z-index: 3;
	cursor: pointer;
}

.select-option:hover {
	background-color: #f2f2f2;
}

.select-option--disabled {
	color: #bcbcbc;
	cursor: default;
}
</style>
