<template>
	<div class="demo-nft">
		<div class="demo-nft__upload">
			<div>
				<input
					type="file"
					id="file"
					name="file"
					accept="image/png, image/jpeg"
					@change="onChange"
				/>
			</div>

			<div class="demo-nft__name">
				Name:
				<input type="text" v-model="imgName" />
			</div>

			<div class="demo-nft__description">
				<label for="nft__description"> Description:</label>
				<textarea
					name="descp"
					id="nft__description"
					cols="30"
					rows="10"
					v-model="description"
				></textarea>
			</div>

			<div>
				<b-button @click="createNFT" variant="primary">Create NFT</b-button>
			</div>

			<div v-if="doneUpload">Upload to NFT Garden Complete!</div>
		</div>
		<div class="demo-nft__show">
			<p>NFTs List:</p>
			<br />
			<div role="tablist">
				<div class="nftItem" v-for="(NFT, index) in NFTs" :key="index">
					<b-button
						class="nftButton"
						v-b-toggle="'accordion-' + index"
						variant="info"
						>{{ NFT.request_metadata.title }}</b-button
					>
					<b-collapse :id="'accordion-' + index">
						<b-card>
							<div class="l-1">
								Description:
								<p>{{ NFT.request_metadata.description }}</p>
							</div>
							<div class="demo-nft__detail">
								Details:
								<p>
									Contract Address:
									<span>{{ NFT.contract_address }}</span>
								</p>
								<p>
									Token ID:
									{{ NFT.token_id }}
								</p>
								<p>
									Chain:
									{{ NFT_CONST.blockchain }}
								</p>
								<p>
									<a
										:href="`https://opensea.io/ja/assets/matic/${NFT_CONST.contract_address}/${NFT.token_id}`"
										target="_blank"
										>Link OpenSea</a
									>
								</p>
							</div>
						</b-card>
					</b-collapse>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NFTService from '@/services/nft.service.js';
import TransferService from '@/services/transfer.service.js';
import { NFT_CONST } from '@/data/constants/CommonConst';

export default {
	name: 'DemoNFT',
	data() {
		return {
			imgName: '',
			file: null,
			doneUpload: false,
			description: '',
			NFTs: [],
			NFT_CONST: NFT_CONST,
		};
	},
	methods: {
		restoreData() {
			this.file = null;
			this.description = '';
			this.imgName = '';
		},

		onChange(event) {
			this.file = event.target.files[0];
		},

		createNFT() {
			var reader = new FileReader();
			reader.readAsDataURL(this.file);
			reader.onload = async () => {
				let [uploadResponse, errorPostImg] = await await TransferService.postNFT({
					content: reader.result.toString(),
					file_name: this.imgName + '.png',
				});
				if (uploadResponse) {
					let key = uploadResponse.key;
					this.uploadNFT(key);
				} else {
					console.log(errorPostImg);
				}
			};
		},

		async uploadNFT(key) {
			let imgURL = await this.getFileUrl(key, 3600);
			console.log({ imgURL });
			let nft = NFT_CONST;
			nft.metadata[0].title = this.imgName;
			nft.metadata[0].description = this.description;
			nft.metadata[0].file_fields[0].url = imgURL;
			let [createNFTResponse, errorCreateNFT] = await NFTService.createNFT(nft);
			if (createNFTResponse) {
				console.log(createNFTResponse);
				this.doneUpload = true;
				this.restoreData();
			} else {
				console.log(errorCreateNFT);
			}
		},

		async getFileUrl(key, expiration) {
			try {
				if (!key) {
					return '';
				}
				let response = await TransferService.get(key, expiration, true);

				console.log('%c Get image url successfully!', 'color: red');
				return response[0].link;
			} catch (error) {
				console.log(error);
				return '';
			}
		},

		async getNFTItems(key, expiration) {
			try {
				// let NFTs = await NFTService.getNFTs(NFT_CONST.contract_address, NFT_CONST.blockchain);
				const response = await NFTService.getNFTs(key, expiration);
				const NFT_gdn_ids = response[0].nft_gdn_ids;
				console.log({ NFT_gdn_ids });

				if (NFT_gdn_ids.length > 0) {
					const response = await NFTService.getNftDetails(NFT_gdn_ids);
					this.NFTs = response[0]?.nfts;
					console.log({ NFTs: this.NFTs });
				}
			} catch (error) {
				console.log(error);
			}
		},
	},

	async created() {
		await this.getNFTItems(NFT_CONST.contract_address, NFT_CONST.blockchain);
	},
};
</script>

<style lang="scss" scoped>
.demo-nft {
	padding: 30px;
	display: grid;
	grid-template-columns: 50% 50%;
	&__upload div {
		margin: 20px 10px;
	}
	&__name input {
		border: 1px solid black !important;
		border-radius: 5px;
	}
	&__description {
		textarea {
			outline: none;
			border: 1px solid black !important;
			border-radius: 5px;
		}
	}
	&__show {
		p {
			white-space: pre-line;
			margin: 10px 20px;
			font-size: 1rem !important;
		}
	}
	&__detail {
		p {
			margin: 10px 20px;
		}
	}

	.nftItem {
		padding: 10px;
	}

	.nftButton {
		width: 100%;
	}

	.card {
		margin: 10px 0;
	}
}
</style>
