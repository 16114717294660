import ApiService from './api.service';

const serviceURL = 'transfer';
const TransferService = {
	/**
	 * Get uploaded file url
	 * @param {String} key
	 * @param {Integer} key
	 * @returns {String} uploaded file url
	 */
	get(key, expiration, check_nft = false) {
		let body = {
			key: key,
			expiration: expiration,
			check_nft: check_nft
		}
		return ApiService.query(`${serviceURL}/preview`, body);
	},

	/**
	 * Upload file to server
	 * @param {Object} body {doc_content: {file Object}}
	 * @returns {Object} uploaded file key
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/upload`, body);
	},

	/**
	 * Delete file
	 * @param {String} key file key
	 * @returns
	 */
	delete(key) {
		let url = `${serviceURL}/delete?key=${key}`;
		return ApiService.delete(url);
	},

	/**
	 * Upload PDF file to server
	 * @param {Object} body {doc_content: {file Object}}
	 * @returns {Object} uploaded PDF file key
	 */
	postPdf(body) {
		return ApiService.post(`${serviceURL}/pdf/s3`, body);
	},

	/**
	 * Upload IMG file to server
	 * @param {Object} content  {file Object} {user_id,fileName}
	 * @returns {Object} uploaded img file key
	 */
	postImg(body) {
		body.role = 'company_seal';
		console.log('body', body);
		return ApiService.post(`${serviceURL}/upload`, body);
	},
	postProfilePicture(body) {
		body.role = 'user_setting'
		console.log('body', body);
		return ApiService.post(`${serviceURL}/upload`, body);
	},
	postNFT(body) {
		body.role = 'nft'
		console.log('body', body)
		return ApiService.post(`${serviceURL}/upload`, body);
	}
};

export default TransferService;
