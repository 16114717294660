import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// TestNFTPage
import DemoNFT from '@/views/Pages/DemoNFT.vue';
//Vuex
import { store } from '../store/store';

import { AUTHORITY } from '../data/constants/CommonConst';
// User Menu pages
const AdminMenu = () => import('@/views/Layout/MenuLayout/AdminMenu.vue');
const ClientMenu = () => import('@/views/Layout/MenuLayout/ClientMenu.vue');
const CreatorMenu = () => import('@/views/Layout/MenuLayout/CreatorMenu.vue');

// Pages
// const User = () => import('@/views/Pages/UserProfile.vue');
const Login = () => import('@/views/Pages/Login.vue');
const Register = () => import('@/views/Pages/Register.vue');
const Lock = () => import('@/views/Pages/Lock.vue');
const ResetPwd = () => import('@/views/Pages/ResetPassword.vue');
const RegisterFinished = () => import('@/views/Pages/RegisterFinished.vue');
const RegisterWaiting = () => import('@/views/Pages/RegisterWaiting.vue');

// TableList pages
const ProjectMenu = () => import('@/views/ProjectManagement/ProjectMenu');

// Project Management
const RegisterProject = () => import('@/views/ProjectManagement/RegisterProject.vue');
const SearchProject = () => import('@/views/ProjectManagement/SearchProject.vue');

// Client Management
const ClientUserRegister = () => import('@/views/Pages/ClientUserRegister.vue');
const ClientSearch = () => import('@/views/ClientManagement/ClientSearch.vue');
const ClientRegister = () => import('@/views/ClientManagement/ClientRegister.vue');
const ClientEdit = () => import('@/views/ClientManagement/ClientEdit.vue');
const EditProject = () => import('@/views/ProjectManagement/EditProject.vue');

// My Page
const MyPage = () => import('@/views/MyPage/MyPage.vue');
const MyPageClient = () => import('@/views/MyPage/MyPageClient.vue');

// Inquiry Form
const InquiryForm = () => import('@/views/InquiryForm/InquiryForm.vue');

//UserMaster
const UserMaster = () => import('@/views/CategoryRegistration/UserMaster.vue');
const UserRegister = () => import('@/views/CategoryRegistration/UserRegister.vue');
const UserEdit = () => import('@/views/CategoryRegistration/UserEdit.vue');
const ManagementMaster = () =>
	import('@/views/CategoryRegistration/ManagementMaster.vue');

//NFT
const NFT = () => import('@/views/Pages/NFT.vue');

let authPages = {
	path: '/adminApp',
	component: AuthLayout,
	name: 'Authentication',
	children: [
		{
			path: 'login',
			name: 'Login',
			component: Login,
		},
		{
			path: 'register',
			name: 'Register',
			component: Register,
		},
		{
			path: 'registerclient',
			name: 'ClientUserRegister',
			component: ClientUserRegister,
		},
		{
			path: 'registerfinished',
			name: 'RegisterFinished',
			component: RegisterFinished,
		},
		{
			path: 'lock',
			name: 'Lock',
			component: Lock,
		},
		{
			path: 'resetpwd',
			name: 'Resetpwd',
			component: ResetPwd,
		},
		{
			path: 'registerwaiting',
			name: 'RegisterWaiting',
			component: RegisterWaiting,
		},
	],
};

let notFoundPage = {
	path: '/page-notfound',
	component: NotFound,
	name: 'NotFound',
};

let demoNFTPage = {
	path: '/demoNFT',
	component: DemoNFT,
	name: 'DemoNFT',
};

let adminMenu = {
	path: '/adminApp/adminmenu/:userId/:authId',
	redirect: '/adminApp/adminmenu/:userId/:authId/projectmenu',
	component: AdminMenu,
	children: [
		{
			path: 'projectmenu',
			component: ProjectMenu,
		},
		{
			path: 'registerproject',
			component: RegisterProject,
		},
		{
			path: 'editproject',
			component: EditProject,
		},
		{
			path: 'searchproject',
			component: SearchProject,
		},
		{
			path: 'searchclient',
			component: ClientSearch,
		},
		{
			path: 'createclient',
			name: 'AdminCreateClient',
			component: ClientRegister,
		},
		{
			path: 'editclient',
			component: ClientEdit,
		},
		{
			path: 'mypage',
			component: MyPage,
		},
		{
			path: 'contact',
			component: InquiryForm,
		},
		{
			path: 'nft',
			component: NFT,
		},
		{
			path: 'usermaster',
			component: UserMaster,
		},
		{
			path: 'userregister',
			component: UserRegister,
		},
		{
			path: 'useredit',
			component: UserEdit,
		},
		{
			path: 'managementmaster',
			component: ManagementMaster,
		},
	],

	beforeEnter: (to, from, next) => {
		if (store.state.isAuthenticated) {
			if (store.state.userInfo.authority === AUTHORITY.ADMIN) {
				next();
			} else {
				next({ name: 'Login' });
			}

			// restore specificRoute in store
			if (store.state.specificRoute.path) store.commit('setSpecificRoute', {});
		} else {
			next({
				name: 'Login',
			});
			store.commit('setSpecificRoute', to);
		}
	},
};

let clientMenu = {
	path: '/adminApp/clientmenu/:userId',
	redirect: '/adminApp/clientmenu/:userId/projectmenu',
	component: ClientMenu,
	name: 'ClientMenu layout',
	children: [
		{
			path: 'projectmenu',
			component: ProjectMenu,
		},
		{
			path: 'searchproject',
			component: SearchProject,
		},
		{
			path: 'mypage',
			component: MyPageClient,
		},
	],
	beforeEnter: (to, from, next) => {
		if (store.state.isAuthenticated) {
			if (store.state.userInfo.authority === AUTHORITY.CLIENT) {
				next();
			} else {
				next({ name: 'Login' });
			}
		} else {
			next({ name: 'Login' });
		}
	},
};

let creatorMenu = {
	path: '/adminApp/creatormenu/:userId/:authId',
	redirect: '/adminApp/creatormenu/:userId/:authId/projectmenu',
	component: CreatorMenu,
	children: [
		{
			path: 'projectmenu',
			component: ProjectMenu,
		},
		{
			path: 'registerproject',
			component: RegisterProject,
		},
		{
			path: 'searchproject',
			component: SearchProject,
		},
		{
			path: 'editproject',
			component: EditProject,
		},
		{
			path: 'searchclient',
			component: ClientSearch,
		},
		{
			path: 'createclient',
			name: 'CreatorCreateClient',
			component: ClientRegister,
		},
		{
			path: 'editclient',
			component: ClientEdit,
		},
		{
			path: 'nft',
			component: NFT,
		},
		{
			path: 'mypage',
			component: MyPage,
		},
		{
			path: 'inquiryform',
			component: InquiryForm,
		},
		{
			path: 'contact',
			component: InquiryForm,
		},
	],
	beforeEnter: (to, from, next) => {
		if (store.state.isAuthenticated) {
			if (store.state.userInfo.authority === AUTHORITY.CREATOR) {
				next();
			} else {
				next({ name: 'Login' });
			}

			// restore specificRoute in store
			if (store.state.specificRoute.path) store.commit('setSpecificRoute', {});
		} else {
			next({ name: 'Login' });
			store.commit('setSpecificRoute', to);
		}
	},
};

const routes = [
	adminMenu,
	clientMenu,
	creatorMenu,
	notFoundPage,
	demoNFTPage,
	authPages,
];

export default routes;
