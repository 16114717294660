import axiosBase from 'axios';

const apiUrl = '/api';

const axios = axiosBase.create({
	// This is development host
	baseURL: process.env.VUE_APP_BASE_URL,
	withCredentials: true,
});

axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
};
const catchError = (error) => {
	console.log(`ApiService: ${error}`);
	if (error.response) {
		// Request made and server responded
		return [undefined, error.response.data];
	} else if (error.request) {
		// The request was made but no response was received
		console.log(error.request);
		return [undefined, error];
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', error);
		return [undefined, error];
	}
};
const responseCallback = (res) => {
	return [res.data,undefined];
};
const ApiService = {
	query(resource, params) {
		return axios.get(`${apiUrl}/${resource}`, {params: params }).then(responseCallback).catch(catchError);
	},
	get(resource) {
		return axios.get(`${apiUrl}/${resource}`).then(responseCallback).catch(catchError);
	},
	post(resource, body) {
		return axios.post(`${apiUrl}/${resource}`, body).then(responseCallback).catch(catchError);
	},
	put(resource, body) {
		return axios.put(`${apiUrl}/${resource}`, body).then(responseCallback).catch(catchError);
	},
	delete(resource) {
		return axios.delete(`${apiUrl}/${resource}`).then(responseCallback).catch(catchError);
	},
	deleteBulk(resource, body) {
		return axios.delete(`${apiUrl}/${resource}`, { data: body }).then(responseCallback).catch(catchError);
	},

};

export default ApiService;
