import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import UserService from '../services/user.service';
import { store } from '../store/store';
Vue.use(VueRouter);

// configure router
const router = new VueRouter({
	mode: 'history',
	hash: false,
	routes: routes, // short for routes: routes
	linkActiveClass: 'active',
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition) {
			return savedPosition;
		}
		if (to.hash) {
			return { selector: to.hash };
		}
		return { x: 0, y: 0 };
	},
});
const unAuthenticateRoutes = ['Login', 'Register', 'Resetpwd', 'RegisterWaiting'];
router.beforeEach(async (to, from, next) => {
	if (!unAuthenticateRoutes.includes(to.name)) {
		let [userInfo, error] = await await UserService.getInfo();
		console.log([userInfo, error]);
		if (userInfo) {
			store.commit('setIsAuthenticated', true);
			store.commit('setUserInfo', userInfo);
		} else {
			store.commit('setIsAuthenticated', false);
			store.commit('setUserInfo', {});
		}
		console.log('userInfo', {
			info: store.state.userInfo,
			isAuthenticated: store.state.isAuthenticated,
		});
	}
	next();
});
export default router;
