const AUTHORITY = {
	CREATOR: 1,
	ADMIN: 2,
	CLIENT: 3,
};

const NFT_CONST = {
	contract_address: "0x0529DAc7ae184482919563D4A650af9764Cbf822",
	blockchain: 'polygon',
	receiver_wallet_address: "0xf91ca85d843823472acc680eac7a0595c889b9ad",
	metadata: [
    {
      title: "",
      description: "Description of NFT",
      file_fields: [
        {
          key: "image",
          url: ""
        }
    ]
    }
  ]
}


module.exports = {
	AUTHORITY,
	NFT_CONST
};
